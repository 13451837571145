import {
  getSectionData,
  getModuleData,
  sectionAdditionalEntries
} from '../constants/reused-initial-states';

import { isObject, isIterable } from './js-helpers';

import { blockTypes /*, mediaBlockTypes, sectionBlockTypes*/ } from '../constants/block-types';

// Get the section depth of an object in the sections hash map.
export const getHashmapDepth = (id = -1, hashmap = {}, idName = 'parentId', itt = 0) => {
  const parentId = hashmap[id] ? hashmap[id][idName] : null;
  return parentId && parentId !== id ? getHashmapDepth(parentId, hashmap, idName, itt + 1) : itt; // Move up the hierarki or return the itteration number
};

export const getTopLevelSection = (sectionNodes) =>
  sectionNodes
    ? Object.values(sectionNodes).find((node) => (node?.parentId ? node.parentId === null : true)) // Top level node might have a parent Id or not.
    : null;
/**
 * getUrlWithNewSectionId - Replaces the parameters from a given route path and replaces sectionId with the given one
 *
 * @param {string} sectionId   Section ID
 * @param {string} routePath   Path given by react-router-redux
 * @param {string} routeParams Route parameters given by react-router-redux
 *
 * @returns {string} Url with the path parameters replaced by values.
 */
export const getUrlWithNewSectionId = (sectionId, routePath, routeParams) => {
  // Collect all the route params and add the new sectionId param to the object.
  const params = {
    ...routeParams,
    sectionId: sectionId
  };

  return Object.entries(params).reduce((acc, [k, v]) => {
    const optionalRouteRegex = new RegExp('\\(([^)]*' + k + '[^)]*)\\)'); // Find everthing within a parentasis which contains the key // https://stackoverflow.com/a/36401815/7197027
    // Take care of section ID first as it might be within parenthesis, maybe not.
    if (k === 'sectionId') {
      const sectionRoute = acc.match(optionalRouteRegex)?.[1] ?? null; // Route without the parens is located in index 1 in the array.
      acc = sectionRoute ? acc.replace(optionalRouteRegex, sectionRoute) : acc;
    }
    if (acc.match(optionalRouteRegex)) {
      // remove conditional parameters, i.e. (/module-1/:moduleId_1)(...
      acc = acc.replace(optionalRouteRegex, '');
    } else if (v) {
      const re = new RegExp(`:${k}`, 'g'); // replace router :parameters with with parameter values
      acc = acc.replace(re, v);
    }
    return acc;
  }, routePath);
};

/**
 * getTransformedSection - Recursive function which homogenizes the data keys to fit into the generic section data
 *
 * @param {object}   data      Section data from server
 * @param {Map}   schema   Key-value pairs of field names matching server-draft equivalents.
 * @param {number}   depth     The depth level in the hierarchy
 *
 * @returns {array} A flattened array with all nested sections and modules refering to their parent
 */
export const getTransformedSection = (data, schema, depth = 0) => {
  let equivalentKey = '';
  let section = []; // a variable is needed to push to.
  if (Array.isArray(data)) {
    // Assuming no need for hierarchical transformation if a schema isn't supplied
    if (isEmptySchema(schema)) {
      return data;
    }
    return [...Object.values(data).map((block) => transformBlockFromSchema(block, schema))];
  }

  return section
    .concat([
      {
        ...Object.entries(data).reduce((acc, [k, v]) => {
          if (schema[k]) {
            equivalentKey = schema[k];

            if (Array.isArray(v)) {
              // Break out sections and modules
              section.push(...v.map((obj) => getTransformedSection(obj, schema, depth + 1)));
              return acc; // skip adding old hierarchical object structures into the transformed object.
            } else {
              return {
                ...acc,
                [equivalentKey]: v
              };
            }
          }

          acc[k] = v;

          return acc;
        }, {})
      }
    ])
    .flat(Infinity); // Flatten array
};

// Traverses an array tree-like structure and returns the id path.
// const getIdPath = (id, data, num = '') => {
//   const parentId = data.find((obj) => obj.id === id)?.parentId ?? null;
//   if (parentId === id) {
//     return num + '' + parentId;
//   }
//   return parentId === null || typeof id === undefined
//     ? num
//     : getIdPath(parentId, data, num + '' + parentId);
// };

// const getTransformedId = (obj, data) => {
//   try {
//     // return obj.id + randomDecimal();
//     const num = getIdPath(obj.id, data);
//     const l = (num + '').length;
//     const decimal = num / Math.pow(10, l);
//     return obj.id + decimal;
//   } catch (e) {
//     throw new Error(
//       `Block didn't contain necessary fields to create id : ${JSON.stringify(obj)}. ${e}`
//     );
//   }
// };

/**
 * getTransformedSectionData - Send the nested data structure off to get transformed and creates a hash map from returned array.
 * The ids and parent ids get transformed in order to avoid id crashes if the backend cannot supply unique ids.
 *
 * @param {object} data    Section data from server
 * @param {object} schema Key-value pairs of field names matching server-draft equivalents.
 *
 * @returns {object} Hash map based on id
 */
export const getTransformedSectionData = (data, schema) => {
  return getTransformedSection(data, schema)
    .map((block) => ({ ...block, ...sectionAdditionalEntries })) // Add additional fields to blocks
    .reduce((acc, obj, idx, srcArray) => {
      acc[obj.id] = {
        ...obj
      };
      return acc;
    }, {}); // convert to hash map
};

export const getTransformedBlock = (data, tempBlock, section, schema = {}) => {
  return getTransformedSection(data, schema).reduce((acc, obj, idx) => {
    // Use the temp block to build up an id which would have otherwise been generated by getTransformedId. This is isn't possible here as the original section object isn't returned by the server, only a single block.
    let transformedId = tempBlock.parentId + '' + obj.id;
    acc[transformedId] = {
      ...obj,
      id: transformedId,
      parentId: tempBlock.parentId
    };
    return acc;
  }, {}); // convert to hash map
};

const transformBlockKeys = (block, schema) => {
  // TODO: Check if settings in empty values for keys helps the situation that the server doesn't pick up changes in blocks.
  return Object.entries(block).reduce((acc, [k, v]) => {
    if (schema.has(k)) {
      acc[schema.get(k)] = v;
    } else {
      acc[k] = v;
    }
    return acc;
  }, {});
};

const transformObjectToItterable = (obj) =>
  Object.entries(obj).reduce((acc, [k, v]) => {
    return acc.set(v, k);
  }, new Map());

const getIterableSchema = (schema) => {
  let itterableSchema = isIterable(schema) ? schema : transformObjectToItterable(schema);
  if (isIterable(itterableSchema) === false && isObject(schema) === false) {
    throw new Error(`Supplied schema was either an object nor itterable: ${schema}`);
  }
  return itterableSchema;
};

export const transformBlockFromSchema = (block, schema) => {
  let itterableSchema = getIterableSchema(schema);
  if (itterableSchema?.size < 1) {
    return block;
  }
  return [...itterableSchema].reduce((acc, [k, v]) => {
    if (k instanceof Function && block[k.name]) {
      acc[v] = k(block); // Using a named function to convert the value of the block
      // if (k.name !== v) {
      //   delete acc[k.name];
      // }
    } else if (block[k] && v instanceof Function) {
      acc[v.name] = v(block);
    } else if (block[k]) {
      acc[v] = block[k]; // Converting the key of the block
      delete acc[k];
    }
    return acc;
  }, block);
};

const getPlacementKey = (child, schemaMap) => {
  return [...schemaMap].reduce((acc, [k, v]) => {
    acc = k instanceof Function && k(child) ? v : acc;
    return acc;
  }, '');
};

const isEmptySchema = (schema) => {
  if (!schema) {
    return true;
  } else if (isIterable(schema) && schema.size) {
    return false;
  } else if (isObject(schema) && Object.values(schema).length) {
    return false;
  }
  return true;
};

/**
 * getSectionsTransformed - Builds up an object hierarchy based on a given hash map. Convers object keys against a schema for the given context.
 *
 * @param {object}  data   Hash map section data
 * @param {Map}  schema    Conversion schema which convers either keys or values in the form of a named function.
 *
 * @returns {Array}  A section object with the same structural properties as originally returned by the server.
 */
export const getSectionsTransformed = (data, schema, hierarchical = false) => {
  if (isEmptySchema(schema)) {
    // Remove ids from hashmap
    return [...Object.values(data)];
  }
  let itterableSchema = getIterableSchema(schema);
  // The schema should be in the order server=>client so that the server equivalents are set as keys.
  // The reason for this is when a section is saved then the SAVE_SECTION_SUCCESS reducer updates the blocks with the new blocks from the server.
  const reversedSchema = [...itterableSchema].reduce((acc, [k, v]) => {
    return acc.set(v, k);
  }, new Map());

  if (hierarchical) {
    return getSectionsTransformedInHierarchy(data, reversedSchema);
  }
  return [...Object.values(data).map((block) => transformBlockFromSchema(block, reversedSchema))];
};

/**
 * getSectionsTransformed - Builds up an object hierarchy based on a given hash map. Convers object keys against a schema for the given context.
 *
 * @param {object}  data   Hash map section data
 * @param {Map}  schema     Conversion schema as a ES6 Map object
 *
 * @returns {array}  A section object with the same structural properties as originally returned by the server.
 */
export const getSectionsTransformedInHierarchy = (data, schema) => {
  return Object.entries(data)
    .sort(([aK, aV], [bK, bV]) => {
      if (!aV?.parentId) {
        return -1; // a is top node. place on top
      } else if (!bV?.parentId) {
        return 1; // b is top node. place on top
      } else if (aV.parentId < bV.parentId) {
        return -1;
      } else if (aV.parentId > bV.parentId) {
        return 1;
      } else {
        // sort on index position within sub modules/sections
        if (aV.index < bV.index) {
          return -1;
        } else if (aV.index > bV.index) {
          return 1;
        } else {
          console.error(`Index sorting produced a same level index for a: ${aV}, b: ${bV} `);
          return 0; // This should never be possible.
        }
      }
    })
    .reverse() // start from the bottom assuming that's where all the sub nodes are.
    .reduce((acc, [k, v]) => {
      const parentId = v.parentId;
      const accValues = acc[v.id] || {};

      if (parentId) {
        let placementKey = getPlacementKey(v, schema);
        // Create new structures if needed.
        if (!acc[parentId]) {
          acc[parentId] = {};
        }
        if (!acc[parentId][placementKey]) {
          acc[parentId][placementKey] = [];
        }
        acc[parentId][placementKey].push({
          ...accValues,
          ...transformBlockKeys(v, schema)
        });
        // delete acc[parentId][placementKey].id;
      } else {
        // Last top node which gets all the accumulated values
        acc = {
          ...accValues,
          ...transformBlockKeys(v, schema)
        };
      }

      if (Object.keys(accValues).length) {
        delete acc[v.id]; // cleanup
      }

      return acc;
    }, {});
};

export const getSectionDataTransformed = (data, schema) =>
  getSectionsTransformed(Object.values(data), schema);

/**
 * getBranchIds - Returns an array of ids from the direct ancestrial relation to
 * the given ID. Id's are converted to strings.
 *
 * @param {string} id       ID of the section/module to be repositioned
 * @param {object} sections hash map
 *
 * @returns {array} array of ids relating to the given ID
 */
const getBranchIds = (id, sections) =>
  Object.entries(sections).reduce((acc, [k, v]) => {
    if (v.parentId === id) {
      // loose comparison
      acc = acc.concat(getBranchIds(v.id + '', sections)); // Get possible grandchildren nodes
    } else if (k === id) {
      // loose comparison
      acc = acc.concat([id + '']);
    }
    return acc;
  }, []);

export const deleteModuleFromSection = (id, sections) => {
  const branchIds = getBranchIds(id, sections);
  return Object.entries(sections).reduce((acc, [k, v]) => {
    return branchIds.includes(k) ? acc : ((acc[k] = v), acc); // Filter out objects of branch ids and retain hash map structure.
  }, {});
};

/**
 * repositionSectionModule - Swaps the position of the section/module to the destination index
 *
 * @param {number} id       ID of the section/module to be repositioned
 * @param {number} nuIndex  index of the new placement of the section/module
 * @param {object} sections Hash map
 *
 * @returns {object} Hash map
 */
export const repositionSectionModule = (id, nuIndex, sections) => {
  const { parentId, index } = sections[id];
  return Object.entries(sections).reduce((acc, [k, v]) => {
    if (v.parentId !== parentId) {
      acc[k] = v; // do nothing
    } else if (v.id === id) {
      acc[k] = {
        ...v,
        index: nuIndex // place current object in new index
      };
    } else if (v.index === nuIndex) {
      acc[k] = {
        ...v,
        index: index // place object in the current object's position
      };
    } else {
      acc[k] = v; // do nothing
    }
    return acc;
  }, {});
};

export const setSectionIndexed = (id, indexed, sections) => {
  if (indexed) {
    sections[id].indexed = true;
    return sections;
  } else {
    // Rule: if a section is not indexed any longer then all subsections are also unindexed.
    const branchIds = getBranchIds(id, sections);
    return Object.entries(sections).reduce((acc, [k, v]) => {
      if (branchIds.includes(k)) {
        acc[k] = {
          ...v,
          indexed: false
        };
      } else {
        acc[k] = v;
      }
      return acc;
    }, {});
  }
};

export const addNewSectionBlock = (selectorObj, sections, additional) => {
  const { parentId, type } = selectorObj;
  const siblingsMaxIndex = Object.values(sections).reduce((acc, item) => {
    acc = item.parentId === parentId ? Math.max(acc, item.index) : acc;
    return acc;
  }, -1);
  const index = siblingsMaxIndex >= 0 ? siblingsMaxIndex + 1 : 0;

  const getNewBlock = () => {
    if (typeof additional?.initialStateFn === 'function') {
      return {
        ...additional?.initialStateFn({ parentId, index, selectorObj }),
        ...sectionAdditionalEntries
      };
    } else if (type === blockTypes.SECTION) {
      return getSectionData({ parentId, index });
    } else {
      return getModuleData({ parentId, index, type });
    }
  };
  const newBlock = getNewBlock();
  return {
    ...sections,
    [newBlock.id]: {
      ...newBlock
    }
  };
};

/**
 * getSaveUrlByAppCode - Gets the section publish url as a function according to app context.
 *
 * @param {string} code Application code
 *
 * @returns {function} Returns a function which consumes an id as parameter
 */
// export const getSaveUrlByAppCode = code => {
//   if (!Object.values(APP_CODES).includes(code)) {
//     console.error(
//       "The given app code given to getSaveUrlByAppCode function doesn't exist : ",
//       code
//     );
//     return null;
//   }
//   // TODO: fill in other urls if applicable.
//   switch (code) {
//     case APP_CODES.GUIDES:
//       return id => `/steps${id ? '/' + id : ''}`;
//     case APP_CODES.AGREEMENTS:
//       return id => `/collectiveagreementdocumentsections/complete${id ? '/' + id : ''}`;
//     case APP_CODES.SUBJECTS:
//       return id => `/subjects/${id}`;
//     case APP_CODES.ARBETSGIVARGUIDEN:
//     case APP_CODES.CONSTANTS:
//     default:
//       return '';
//   }
// };

// export const getDeleteUrlByAppCode = code => {
//   if (!Object.values(APP_CODES).includes(code)) {
//     console.error(
//       "The given app code given to getDeleteUrlByAppCode function doesn't exist : ",
//       code
//     );
//     return null;
//   }
//   // TODO: fill in other urls if applicable.
//   switch (code) {
//     case APP_CODES.GUIDES:
//       return id => `/steps/${id}`;
//     case APP_CODES.AGREEMENTS:
//       return id => `/collectiveagreementdocumentsections/${id}`;
//     case APP_CODES.ARBETSGIVARGUIDEN:
//     case APP_CODES.SUBJECTS:
//     case APP_CODES.CONSTANTS:
//     default:
//       return '';
//   }
// };
